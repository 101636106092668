'use client'

import { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'

export default function Home() {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current!
    if (!canvas) return

    const ctx = canvas.getContext('2d')!
    if (!ctx) return

    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    const particles: Particle[] = []
    const particleCount = 100
    const maxDistance = 100

    class Particle {
      x: number
      y: number
      size: number
      speedX: number
      speedY: number

      constructor() {
        this.x = Math.random() * canvas.width
        this.y = Math.random() * canvas.height
        this.size = Math.random() * 2 + 0.5
        this.speedX = Math.random() * 0.5 - 0.25
        this.speedY = Math.random() * 0.5 - 0.25
      }

      update() {
        this.x += this.speedX
        this.y += this.speedY

        if (this.x < 0 || this.x > canvas.width) this.speedX *= -1
        if (this.y < 0 || this.y > canvas.height) this.speedY *= -1
      }

      draw() {
        ctx!.beginPath()
        ctx!.arc(this.x, this.y, this.size, 0, Math.PI * 2)
        ctx!.fillStyle = 'rgba(200, 200, 200, 0.5)'
        ctx!.fill()
      }
    }

    for (let i = 0; i < particleCount; i++) {
      particles.push(new Particle())
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      for (let i = 0; i < particles.length; i++) {
        particles[i].update()
        particles[i].draw()

        for (let j = i + 1; j < particles.length; j++) {
          const dx = particles[i].x - particles[j].x
          const dy = particles[i].y - particles[j].y
          const distance = Math.sqrt(dx * dx + dy * dy)

          if (distance < maxDistance) {
            ctx.beginPath()
            ctx.moveTo(particles[i].x, particles[i].y)
            ctx.lineTo(particles[j].x, particles[j].y)
            ctx.strokeStyle = `rgba(200, 200, 200, ${1 - distance / maxDistance})`
            ctx.stroke()
          }
        }
      }

      requestAnimationFrame(animate)
    }

    animate()

    const handleResize = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="relative h-screen w-screen overflow-hidden bg-black text-white">
      <canvas
        ref={canvasRef}
        className="absolute inset-0 opacity-30"
        aria-hidden="true"
      />
      <div className="relative z-10 flex h-full w-full flex-col items-center justify-between p-8">
        <header className="w-full text-center">
          <h1 className="text-4xl font-bold tracking-wider">
            <GlitchText text="Mohammad" />
          </h1>
        </header>
        <main className="flex flex-grow flex-col items-center justify-center space-y-8">
          <h2 className="text-center text-4xl font-bold tracking-widest sm:text-5xl">
            <GlitchText text="Coding" />
            <GlitchText text="Fortress" />
          </h2>
          <a href="https://ai-app.codebymoh.com">
            <motion.button
              className="rounded-full bg-white px-6 py-3 text-lg font-semibold text-black transition-colors hover:bg-gray-200"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Launch AI App
            </motion.button>
          </a>
        </main>
        <footer className="w-full text-center">
          <p className="text-sm tracking-wide opacity-70">Explore the mystery</p>
        </footer>
      </div>
    </div>
  )
}

function GlitchText({ text }: { text: string }) {
  return (
    <motion.span
      className="relative inline-block"
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      <motion.span
        className="absolute left-[1px] top-[1px] text-red-500"
        animate={{ x: [-1, 1], y: [-1, 1] }}
        transition={{ duration: 0.1, repeat: Infinity }}
      >
        {text}
      </motion.span>
      <motion.span
        className="absolute left-[-1px] top-[-1px] text-blue-500"
        animate={{ x: [1, -1], y: [1, -1] }}
        transition={{ duration: 0.1, repeat: Infinity }}
      >
        {text}
      </motion.span>
      <span className="relative z-10">{text}</span>
    </motion.span>
  )
}

